import '../../styles/common.css';

import React, { useReducer } from 'react';

import Constants from '../../constants/api';
import InternetPackage from '../../components/utils/InternetPackage';
import Layout from '../../components/layout';
import PackageSection from '../../components/home/package-section';
import SEO from '../../components/seo';
import SectionTitles from '../../components/shared/section-titles';
import ShareTab from '../../components/shared/share-tab';
import TvPackage from '../../components/utils/TvPackage';
import { graphql } from 'gatsby';
import ogImage from '../../images/landing-pages/koledata-e-vyzmojna.png';

const packageSize = (i) => {
	switch (i) {
		case 0:
		case 3:
			return 'mid';
		case 1:
		case 4:
			return 'big';
		case 2:
		case 5:
			return 'xl';
		default:
			return 'big';
	}
};

export default ({ data }) => {
	const tvPackages = data.homePackages.edges
		.filter((node) => node.node.package !== 'Само интернет')
		.map(({ node }, index) => {
			return new TvPackage(
				node.packageid,
				packageSize(index),
				node.speed,
				node.price,
				{ ...node },
				node.package,
				'Sit anim qui in pariatur eiusmod amet dolore minim nostrud.',
				node.tvcount,
				node.ont,
				node.ontcount
			);
		});

	// const internetPackages = data.homePackages.edges
	//   .filter((node) => node.node.package === 'Само интернет')
	//   .map(({ node }, index) => {
	//     return new InternetPackage(node.packageid, packageSize(index), node.speed, node.price, { ...node });
	//   });

	return (
		<Layout landing="koleda2020FB">
			<SEO
				title="Коледата е възможна с подаръци за всички"
				description="Под елхата на NetSurf ще има подаръци за всички. Това е нашият начин да кажем “Благодаря!”"
				overwriteMeta={[
					{
						name: 'robots',
						content: 'noindex'
					},
					{
						name: 'og:url',
						content: 'https://netsurf.bg/landing-pages/koledata-vyzmojna/'
					},
					{
						name: 'og:image',
						content: ogImage
					}
				]}
			/>
			<style
				dangerouslySetInnerHTML={{
					__html: `
        footer,
        .top-nav,
        .navbar-toggler,
        .navbar .navbar-collapse .navbar-nav,
        #pcta-header
        {
          display: none !important;
        }
        .navbar .order-0 {
          pointer-events: none;
          margin-right: -50px;
        }
        .navbar-collapse {
          justify-content: flex-end;
        }
        .header-button {
          display: block;
        }

        @media screen and (max-width: 575px) {
          .navbar .green.header-button {
            padding-left: 10px;
            padding-right: 10px;
            min-width: initial;
          }
        }
      `
				}}
			/>
			<div style={{ paddingTop: '16rem' }}>
				<SectionTitles>
					<h1>Коледата е възможна с подаръци за всички</h1>
					<hr className="center" />
				</SectionTitles>
			</div>

			<div className="container">
				<div className="row d-flex">
					<div className="col-12 col-lg-6">
						<img
							src={data.mainImage.childImageSharp.fluid.src}
							className="img-fluid mb-4"
							alt="Коледата е възможна с подаръци за всички"
						/>
					</div>
					<div className="col-12 col-lg-6">
						<p>
							Това е нашият начин да кажем “благодаря” на всички наши лоялни
							клиенти, както и на новите ни приятели сърфисти (клиенти).
						</p>
						<p>
							Под елхата на NetSurf можеш да намериш своя персонализиран{' '}
							<strong>
								подарък под формата на допълнителна екстра към пакетен
								план, % намаление или живо зелено борче
							</strong>
							, с което да добавиш още повече уют вкъщи. За да направим
							коледните празници още по-щастливи за всички теб и цялото ти
							семейство, организираме и томбола с награди. Разбери пълните
							условия и какво можеш да спечелиш по-долу и участвай.
						</p>
					</div>
				</div>

				<div className="row d-flex mt-4 mb-4">
					<div className="col-12">
						<BookingForm />
					</div>
				</div>

				<div className="row d-flex mb-4">
					<div className="col-12">
						<h2 className="mt-4">Подаръци за нови клиенти и условия:</h2>

						<ul className="mb-5 text-left blue-ticks">
							<li className="my-2">
								Стани нов клиент на пакет “Среден” и получи 1 бонус месец
								с допълнителни екстри като връщане на ТВ съдържание от 24
								до 120 часа назад.
							</li>
							<li className="my-2">
								Стани нов клиент на пакет “Голям” и получи 1 бонус месец
								допълнителна екстра връщане на ТВ съдържание до 24 часа
								назад, възможност за предплащане 2=4 и избор между 15%
								еднократна отстъпка от месечната такса или живо борче.
							</li>
							<li className="my-2">
								Стани нов клиент на пакет “Пълна програма” и получи бонус
								допълнителна екстра връщане на ТВ съдържание до 24 часа
								назад за целия срок на договора, възможност за предплащане
								2=4 и избор между 20% еднократна отстъпка от месечната
								такса или живо борче.
							</li>
							<li className="my-2">
								Стани нов клиент на пакет “Интернет” и плати 2 месеца за
								3.
							</li>
						</ul>

						<p>
							Всички нови клиенти на пакетна услуга участват в играта ни за
							1 бр. телевизор, 1 бр. лаптоп и 2 бр. таблета, като
							единственото условие е да са платили абонамента си за месец
							декември.
						</p>

						<h2 className="mt-4">Подаръци за текущи клиенти и условия:</h2>

						<ul className="mb-5 text-left blue-ticks">
							<li className="my-2">
								Поднови текущия абонаментен план към пакет “Среден” и
								получи 1 бонус месец с допълнителни екстри като връщане на
								ТВ съдържание от 24 до 120 часа назад.
							</li>
							<li className="my-2">
								Поднови текущия абонаментен план към пакет “Голям” и
								получи 1 бонус месец допълнителна екстра връщане на ТВ
								съдържание до 24 часа назад, възможност за предплащане 2=3
								и избор между 15% еднократна отстъпка от месечната такса
								или живо борче.
							</li>
							<li className="my-2">
								Поднови текущия абонаментен план към пакет “Пълна
								програма” и получи бонус допълнителна екстра връщане на ТВ
								съдържание до 24 часа назад за целия срок на договора,
								възможност за предплащане 2=3 и избор между 20% еднократна
								отстъпка от месечната такса или живо борче.
							</li>
						</ul>

						<p>
							Всички настоящи клиенти на пакетна услуга участват в играта ни
							за 1 бр. телевизор, 1 бр. лаптоп и 2 бр. таблета, като
							единственото условие е да са платили абонамента си за месец
							декември.
						</p>

						<h2 className="mt-4">Времеви обхват:</h2>
						<p>1 декември 2020 г. – 31 декември 2020 г.</p>

						<p>
							<small>
								<em>
									*Томболата за големите награди от играта – телевизор
									(1 бр.), лаптоп (1 бр.) и таблет (2 бр.) – ще се
									проведе на 07.01.2021 година.
								</em>
							</small>
							<br />
							<small>
								<em>
									**Кампанията важи за физически лица и не се комбинира
									с други текущи кампании на NetSurf.
								</em>
							</small>
							<br />
							<small>
								<em>
									***Еднократните отстъпки се начисляват за последващата
									такса от абонаментния план на клиента.
								</em>
							</small>
							<br />
						</p>

						<p>Успех на всички!</p>
					</div>
				</div>

				<PackageSection
					readOnly={true}
					enableToggle={false}
					combinedPackages={tvPackages}
				/>
				{/* internetPackages={internetPackages} */}

				<ShareTab path="/landing-pages/koledata-vyzmojna" />
			</div>
		</Layout>
	);
};

const BookingForm = () => {
	const reducer = (state, action) => {
		switch (action.type) {
			case 'field': {
				return {
					...state,
					[action.field]: action.value
				};
			}
			case 'success': {
				return {
					...state,
					success: true
				};
			}
			case 'valid_fields': {
				return {
					...state,
					firstNameIsValid: action.firstNameIsValid,
					lastNameIsValid: action.lastNameIsValid,
					emailIsValid: action.emailIsValid,
					telephoneIsValid: action.telephoneIsValid
				};
			}
			case 'failure': {
				return {
					...state,
					failure: true
				};
			}
			default: {
				return state;
			}
		}
	};

	const initialState = {
		firstName: '',
		lastName: '',
		email: '',
		telephone: '',
		agreeCheckbox: false,
		firstNameIsValid: true,
		lastNameIsValid: true,
		emailIsValid: true,
		telephoneIsValid: true,
		success: false,
		failure: false
	};

	const [state, dispatch] = useReducer(reducer, initialState);

	const {
		firstName,
		lastName,
		email,
		telephone,
		agreeCheckbox,
		firstNameIsValid,
		lastNameIsValid,
		emailIsValid,
		telephoneIsValid,
		success,
		failure
	} = state;

	const onSubmit = (e) => {
		e.preventDefault();

		if (checkFields()) {
			let send_to = 'callcenter@netsurf.bg';
			const subject = 'Кампания: Коледата е възможна (Facebook)';
			const body =
				'<br>Име: ' +
				firstName +
				'<br />Фамилия: ' +
				lastName +
				'<br>Имейл: ' +
				email +
				'<br>Телефон: ' +
				telephone;
			const reply_to = email;

			const emailObj = {
				send_to: send_to,
				subject: subject,
				body: body,
				reply_to: reply_to
			};

			const headers = new Headers();
			headers.append('Content-Type', 'application/json');

			const POSToptions = {
				method: 'POST',
				headers: headers,
				body: JSON.stringify(emailObj)
			};

			const request = new Request(Constants.MAIL_URL, POSToptions);
			fetch(request)
				.then((res) => {
					if (res.status === 200) {
						dispatch({ type: 'success' });
					} else {
						dispatch({ type: 'failure' });
					}
				})
				.catch((e) => {
					dispatch({ type: 'failure' });
				});
		}
	};

	const checkFields = () => {
		const fn = validateField('firstName', firstName);
		const ln = validateField('lastName', lastName);
		const e = validateField('email', email);
		const t = validateField('telephone', telephone);

		dispatch({
			type: 'valid_fields',
			firstNameIsValid: fn,
			lastNameIsValid: ln,
			emailIsValid: e,
			telephoneIsValid: t
		});
		return fn && ln && e && t && agreeCheckbox;
	};

	return (
		<>
			{success ? (
				<>
					<h2>Успешно се свързахте с нас!</h2>
					<p>Очаквайте отговор в най-скоро време.</p>
				</>
			) : (
				<form className="popup-form" onSubmit={onSubmit}>
					<div className="form-row">
						<div className="form-group col-md-6">
							<label htmlFor="firstName">Име</label>
							<input
								type="text"
								className="form-control"
								id="firstName"
								placeholder="Въведете име"
								value={firstName}
								onFocus={(e) => (e.target.placeholder = '')}
								onBlur={(e) => (e.target.placeholder = 'Въведете име')}
								onChange={(v) =>
									dispatch({
										type: 'field',
										field: 'firstName',
										fieldValid: 'firstNameIsValid',
										value: v.currentTarget.value
									})
								}
							/>
							{!firstNameIsValid && (
								<small className="text-danger">Минимум 2 символа</small>
							)}
						</div>
						<div className="form-group col-md-6">
							<label htmlFor="lastName">Фамилия</label>
							<input
								type="text"
								className="form-control"
								id="lastName"
								placeholder="Въведете име"
								value={lastName}
								onFocus={(e) => (e.target.placeholder = '')}
								onBlur={(e) =>
									(e.target.placeholder = 'Въведете фамилия')
								}
								onChange={(v) =>
									dispatch({
										type: 'field',
										field: 'lastName',
										fieldValid: 'lastNameIsValid',
										value: v.currentTarget.value
									})
								}
							/>
							{!lastNameIsValid && (
								<small className="text-danger">Минимум 2 символа</small>
							)}
						</div>

						<div className="form-group col-md-6">
							<label htmlFor="email">Вашият имейл</label>
							<input
								type="text"
								className="form-control"
								id="email"
								placeholder="Въведете имейл"
								value={email}
								onFocus={(e) => (e.target.placeholder = '')}
								onBlur={(e) => (e.target.placeholder = 'Въведете имейл')}
								onChange={(v) =>
									dispatch({
										type: 'field',
										field: 'email',
										fieldValid: 'emailIsValid',
										value: v.currentTarget.value
									})
								}
							/>
							{!emailIsValid && (
								<small className="text-danger">Невалиден мейл</small>
							)}
						</div>

						<div className="form-group col-md-6">
							<label htmlFor="telephone">Телефон</label>
							<input
								type="text"
								className="form-control"
								id="telephone"
								placeholder="Въведете телефон"
								value={telephone}
								onFocus={(e) => (e.target.placeholder = '')}
								onBlur={(e) =>
									(e.target.placeholder = 'Въведете телефон')
								}
								onChange={(v) =>
									dispatch({
										type: 'field',
										field: 'telephone',
										fieldValid: 'telephoneIsValid',
										value: v.currentTarget.value
									})
								}
							/>
							{!telephoneIsValid && (
								<small className="text-danger">Невалиден телефон</small>
							)}
						</div>
					</div>
					<div className="w-100 text-center text-sm-right">
						<button
							type="submit"
							className="btn btn-primary green mt-5"
							disabled={!agreeCheckbox}
						>
							Заяви
						</button>
						<div className="form-check">
							<input
								type="checkbox"
								className="form-check-input"
								id="agreeCheckbox"
								value={agreeCheckbox}
								onChange={(v) => {
									dispatch({
										type: 'field',
										field: 'agreeCheckbox',
										value: !agreeCheckbox
									});
								}}
							/>
							<label className="form-check-label" htmlFor="agreeCheckbox">
								Съгласен съм да съберете личните ми данни по{' '}
								<a
									href="https://netsurf.bg/wp-content/uploads/2019/12/GDPR_02_12_2019.pdf"
									target="_blank"
									rel="noopener noreferrer"
								>
									GDPR
								</a>
								.
							</label>
						</div>
					</div>
					{failure && (
						<p className="text-danger">Грешка при изпращането! Опитай пак.</p>
					)}
				</form>
			)}
		</>
	);
};

export const validateField = (field, value) => {
	switch (field) {
		case 'firstName':
		case 'lastName':
			return value.length >= 1;
		case 'message':
			return value.length >= 1;
		case 'date':
			return value !== null;
		case 'telephone':
			return isValidTelephone(value);
		case 'email':
			return isValidMail(value);
		case 'file':
			return value != null;
		default:
			return false;
	}
};

const isValidTelephone = (phone) => {
	const r = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
	return r.test(phone);
};

const isValidMail = (email) => {
	const r = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return r.test(String(email).toLowerCase());
};

export const query = graphql`
	query LandingKoledata2020FBPage {
		mainImage: file(relativePath: { eq: "landing-pages/koledata-e-vyzmojna.png" }) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid_withWebp_noBase64
				}
			}
		}

		homePackages: allGoogleDevonlySheet(
			filter: { packageId: { in: [2, 3, 4, 16, 22, 28] } }
		) {
			edges {
				node {
					id
					packageid: packageId
					speed
					package
					tvcount: tvCount
					duration
					price
					internalname: internalName
					includedextras: includedExtras
					pauses
					informationButton
					timeshift: timeShift
					archive
					record
					ont
					ontcount: ontCount
				}
			}
		}
	}
`;

